import { useCallback, useEffect, useRef, useState } from "react";
import { Board } from "./Board";
import { Button } from "./Button";
import { ScoreBoard } from "./ScoreBoard";
import { Tile } from "./game";
import styled from "styled-components";
import domToImage from 'dom-to-image';
import { BagCode, safeDate } from "./BagCode";
import { trackShareSummary } from "./analytics";

interface SummaryProps {
  bag: Tile[],
  score: number;
  board: Tile[][];
  goToMenu: () => void;
  code: string;
  bagDate: Date | string | undefined;
  sturdyBonus: boolean;
  allTilesBonus: boolean;
  backText?: string;
}

const DOM_SCALE = 6;

export const Summary: React.FC<SummaryProps> = ({
  bag,
  bagDate,
  score,
  goToMenu,
  board,
  code,
  sturdyBonus,
  allTilesBonus,
  backText
}) => {
  const summaryRef = useRef<HTMLDivElement>(null);
  const [summaryBlob, setSummaryBlob] = useState<Blob>();
  const [boardBlob, setBoardBlob] = useState<Blob>();
  const [summaryImage, setSummaryImage] = useState("");
  const [boardImage, setBoardImage] = useState("");
  const [showWords, setShowWords] = useState(false);

  const theImage = (showWords ? boardImage : summaryImage);

  const textSummary = `${bagDate ? `🗓️ ${safeDate(bagDate).toLocaleString(undefined, {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
  })}` : `🎲 ${code.toUpperCase()}`}: ${score} ${sturdyBonus ? '✨' : '💥'}${allTilesBonus ? '💯' : ''}`;
  const fullTextNoURL = `Lexible ${textSummary}`;
  const fullTextSummary = `${fullTextNoURL} https://lexible.xyz`;

  const genImage = useCallback(() => {
    if (theImage) return;
    const node = summaryRef.current;
    if (node) {
      const options = {
        width: node.clientWidth * DOM_SCALE,
        height: node.clientHeight * DOM_SCALE,
        bgcolor: "#a6c6b2",
        style: {
          transform: `scale(${DOM_SCALE})`,
          transformOrigin: 'top left'
        }
      };
      const {setImage, setBlob} = showWords
        ? {setImage: setBoardImage, setBlob: setBoardBlob}
        : {setImage: setSummaryImage, setBlob: setSummaryBlob};
      domToImage.toPng(node, options).then(setImage);
      domToImage.toBlob(node, options).then(setBlob);
    }
  }, [summaryRef, showWords, theImage])

  useEffect(() => {
    setTimeout(genImage);
  }, [genImage]);

  const revealWords = () => {
    setShowWords(true);
    setTimeout(genImage);
  }

  const shareBlob = showWords ? boardBlob : summaryBlob;
  const shareData = shareBlob ? {
    files: [
      new File([shareBlob], "lexible-" + code + ".png", {
        type: shareBlob.type,
      }),
    ],
    title: "Lexible",
    text: fullTextNoURL,
    url: "https://lexible.xyz"
  } : undefined;

  const canShare = () => {
    try {
      return navigator.canShare !== undefined && shareData && navigator.canShare(shareData);
    } catch (error) {
      return false;
    }
  }

  const shareEnabled = canShare();

  const onShare = () => {
    try {
      if (navigator.canShare(shareData)) {
        navigator.share(shareData)
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error);
    }
    trackShareSummary({});
  }

  const onSaveImage = (image: string) => {
    var a = document.createElement('a');
    a.href = image;
    a.download = "tile-tower-" + code + ".png";
    document.body.appendChild(a);
    a.click();  
    document.body.removeChild(a);
  }

  const [copied, setCopied] = useState(false);
  const copyText = () => {
    try {
      navigator.clipboard.writeText(fullTextSummary).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      }
      );
    } catch (e) {
      console.error(e);
    }
  }

  return <SummaryDiv>
    <Title>lexible</Title>
    { !theImage && <ImageDiv ref={summaryRef}>
      <GameAreaDiv>
        <Row>{!showWords && <BagCode code={code} date={bagDate} fixWidth={false} />} <ScoreBoard score={score} /></Row>
        <Board 
          words={board} 
          hideLetters={!showWords} 
          selectedTiles={[]} 
          arrangement={[]} 
          triedIllegalWord={false} 
          sturdyBonus={sturdyBonus}
          allTilesBonus={allTilesBonus}
        />
      </GameAreaDiv>
    </ImageDiv>}
    { theImage && 
      <SaveImage 
        width="100px"
        src={theImage} 
        alt="Crosswordle results summary" 
        onClick={() => onSaveImage(theImage)}
      /> 
    }
    <BottomRow>
      <TextSummary><TextSummaryInner>{textSummary}</TextSummaryInner></TextSummary> { navigator.clipboard !== undefined && <Button onClick={copyText}><CopyButtonText>{copied ? '(done)' : 'Copy'}</CopyButtonText></Button>}
    </BottomRow>
    <BottomRow>
      <Button 
        onClick={() => shareEnabled ? onShare() : onSaveImage(theImage)} 
        disabled={!theImage}
      >Share</Button>
      <Button onClick={goToMenu}>{backText ?? 'Menu'}</Button>
      { !showWords && <Button 
        onClick={revealWords} 
        disabled={!summaryImage}
        primary="#e4e4e4" 
        secondary="#b4b4b4"
      >Show Words</Button>}
      { showWords && <Button 
        onClick={() => setShowWords(false)} 
        disabled={!summaryImage}
        primary="#e4e4e4" 
        secondary="#b4b4b4"
      >Hide Words</Button>}
    </BottomRow>
    
  </SummaryDiv>;
}

const CopyButtonText = styled.div`
  width: 60px;
  text-align: center;
`;

const Title = styled.div`
  font-size: 30px;
  font-family: "Courier New";
  user-select: none;
  color: black;
  margin-bottom: 10px;
  text-align: center;
`;

const TextSummary = styled.div`
  height: 15px;
  padding: 5px 10px;
  display: inline-block;
  background-color: #fcf6d3;
  border-radius: 5px;
  border: 1px solid #e0d48f;
  box-shadow: -1px 1px #e0d48f;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
`;

const TextSummaryInner = styled.div`
  font-size: 16px;
  font-family: "Courier New";
  user-select: none;
  color: #4e493d;
`

const GameAreaDiv = styled.div`
  overflow: hidden;
  display: flex;
  margin: 10px;
  gap: 10px;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ImageDiv = styled.div`
  overflow: hidden;
  height: calc(min(100vw + 10px, 500px));
  width: calc(min(100vw + 10px, 500px));
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const SummaryDiv = styled.div`
  display: flex;
  gap: 10px;
  height: 100%;
  padding: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BottomRow = styled.div`
  display: flex;
  padding: 10px;
  gap: 20px;
  justify-content: center;
`;

const SaveImage = styled.img`
  height: calc(min(100vw + 10px, 500px));
  width: calc(min(100vw + 10px, 500px));
  cursor: pointer;
`;