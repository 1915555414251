import styled from "styled-components";

interface BagButtonProps {
  tileCount: number;
  openBag: () => void;
}

export const BagButton: React.FC<BagButtonProps> = ({ 
  tileCount,
  openBag
}) => {
  return <BagButtonSpan onClick={openBag}>
    <TileInner>Bag: {tileCount}</TileInner>
  </BagButtonSpan>;
}

const BagButtonSpan = styled.div`
  height: 15px;
  padding: 5px 10px;
  display: inline-block;
  border-radius: 5px;
  background-color: #d3d9fc;
  border: 1px solid #a3aad2;
  box-shadow: -1px 1px #a3aad2;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  margin-left: 10px;
  cursor: pointer;
`;

const TileInner = styled.div`
  font-size: 16px;
  font-family: "Courier New";
  user-select: none;
  color: #4e493d;
`;