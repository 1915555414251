import styled from "styled-components";
import { Button } from "./Button";
import { useEffect, useState } from "react";
import { Tile } from "./game";
import { Tower } from "./Tower";
import { Board } from "./Board";

interface InstructionsProps {
  showGame: () => void;
}

function l(letter: string, id?: number, opt?: {add?: number, multiply?: number}) {
  return {letter, id, add: opt?.add, multiply: opt?.multiply};
}

interface Page {
  text: string;
  initial: {pot: Tile[], board: Tile[][], selected: Tile[], draft: Tile[]};
  transitions: ((props: {
    pot: Tile[], 
    setPot: (pot: Tile[]) => void, 
    board: Tile[][], 
    setBoard: (board: Tile[][]) => void, 
    draft: Tile[], 
    setDraft: (draft: Tile[]) => void;
    isToppling: boolean;
    setIsToppling: (isToppling: boolean) => void;
  }) => void)[];
}

const PAGES: Page[] = [
  {
    text: 'In Lexible, your goal is to spell words, the longer the better. First draw tiles into your hand.',
    initial: {pot: [], board: [], selected: [], draft: []},
    transitions: [
      ({pot, setPot}) => { setPot([...pot, {letter: "b", id: 0}]); },
      ({pot, setPot}) => { setPot([...pot, {letter: "t", id: 1}]); },
      ({pot, setPot}) => { setPot([...pot, {letter: "l", id: 2}]); },
      ({pot, setPot}) => { setPot([...pot, {letter: "p", id: 3}]); },
      ({pot, setPot}) => { setPot([...pot, {letter: "e", id: 4}]); },
      ({pot, setPot}) => { setPot([...pot, {letter: "i", id: 5}]); }
    ],
  },
  {
    text: 'Next, type out a word (4-letter minimum) using those letters and hit "enter" to add it to your board.',
    initial: {pot: [
      {letter: "b", id: 0},
      {letter: "t", id: 1},
      {letter: "l", id: 2},
      {letter: "p", id: 3},
      {letter: "e", id: 4},
      {letter: "i", id: 5}
    ], board: [], selected: [], draft: []},
    transitions: [
      ({setDraft}) => { setDraft([{letter: "t", id: 1}]); },
      ({draft, setDraft}) => { setDraft([...draft, {letter: "i", id: 5}]); },
      ({draft, setDraft}) => { setDraft([...draft, {letter: "l", id: 2}]); },
      ({draft, setDraft}) => { setDraft([...draft, {letter: "e", id: 4}]); },
      () => {},
      ({setBoard, setDraft, setPot}) => {
        setBoard([[
          {letter: "t", id: 1},
          {letter: "i", id: 5},
          {letter: "l", id: 2},
          {letter: "e", id: 4}
        ]]);
        setDraft([]);
        setPot([
          {letter: "b", id: 0},
          {letter: "p", id: 3},
        ]);
      }
    ],
  },
  {
    text: "Then you'll need to draw more tiles to continue.",
    initial: {pot: [
      {letter: "b", id: 0},
      {letter: "p", id: 3},
    ], board: [[
      {letter: "t", id: 1},
      {letter: "i", id: 5},
      {letter: "l", id: 2},
      {letter: "e", id: 4}
    ]], selected: [], draft: []},
    transitions: [
      ({ pot, setPot }) => { setPot([...pot, l('s', 6)]) },
      ({ pot, setPot }) => { setPot([...pot, l('d', 7)]) }
    ]
  },
  {
    text: "Now you can build off your existing word to create a longer one.",
    initial: {
      pot: [l('b', 0), l('p', 3), l('s', 6), l('d', 7)], 
      board: [[l("t", 1), l("i", 5), l("l", 2), l("e", 4) ]], 
      selected: [],
      draft: []
    },
    transitions: [
      ({setDraft}) => { setDraft([l('l', 2)]); },
      ({draft, setDraft}) => { setDraft([...draft, l("i", 5)]); },
      ({draft, setDraft}) => { setDraft([...draft, l('s', 6)]); },
      ({draft, setDraft}) => { setDraft([...draft, l("t", 1)]); },
      ({draft, setDraft}) => { setDraft([...draft, l("e", 4)]); },
      ({draft, setDraft}) => { setDraft([...draft, l('d', 7)]); },
      () => {},
      ({setBoard, setDraft, setPot}) => {
        setBoard([[l('l', 2), l("i", 5), l('s', 6), l("t", 1), l("e", 4), l('d', 7)]]);
        setDraft([]);
        setPot([l('b', 0), l('p', 3)]);
      }
    ]
  },
  {
    text: "If you draw too many tiles, you'll go bust and lose them all! You're more likely to go bust as the number of words you can spell from your hand increases.",
    initial: {
      pot: [l('b', 0), l('p', 3)], 
      board: [[l('l', 2), l("i", 5), l('s', 6), l("t", 1), l("e", 4), l('d', 7)]], 
      selected: [],
      draft: []
    },
    transitions: [
      ({ pot, setPot }) => { setPot([...pot, l('s', 8)]) },
      ({ pot, setPot }) => { setPot([...pot, l('e', 9)]) },
      ({ pot, setPot }) => { setPot([...pot, l('q', 87)]) },
      ({ pot, setPot }) => { setPot([...pot, l('e', 10)]) },
      ({ pot, setPot }) => { setPot([...pot, l('x', 88)]) },
      ({ pot, setPot }) => { setPot([...pot, l('r', 11)]) },
      ({ pot, setPot }) => { setPot([...pot, l('z', 89)]) },
      ({ pot, setPot }) => { setPot([...pot, l('t', 12)]) },
      ({ pot, setPot, setIsToppling }) => { 
        setPot([...pot, l('a', 13)]); 
        setIsToppling(true); 
      },
      () => {},
      ({ setPot, setIsToppling }) => { 
        setPot([]); 
        setIsToppling(false); 
      },
    ]
  },
  {
    text: "4-letter words are worth 1 point, and each letter after that adds 1. Double- and triple-letter tiles are worth 2 and 3 points each!",
    initial: {
      pot: [], 
      board: [[l('l', 2), l("i", 5), l('s', 6), l("t", 1), l("e", 4), l('d', 7)]],
      selected: [],
      draft: []
    },
    transitions: [
      ({ pot, setPot }) => { setPot([...pot, l('d', 15, { add: 1 })]) },
      ({ pot, setPot }) => { setPot([...pot, l('o', 16)]) },
      ({ pot, setPot }) => { setPot([...pot, l('r', 17, { add: 2 })]) },
      ({ pot, setPot }) => { setPot([...pot, l('w', 18, { add: 1 })]) },
      ({draft, setDraft}) => { setDraft([...draft, l('w', 18, { add: 1 })]); },
      ({draft, setDraft}) => { setDraft([...draft, l('o', 16)]); },
      ({draft, setDraft}) => { setDraft([...draft, l('r', 17, { add: 2 })]); },
      ({draft, setDraft}) => { setDraft([...draft, l('d', 15, { add: 1 })]); },
      () => {},
      ({setBoard, setDraft, setPot}) => {
        setBoard([
          [l('l', 2), l("i", 5), l('s', 6), l("t", 1), l("e", 4), l('d', 7)],
          [
            l('w', 18, { add: 1 }), 
            l('o', 16), 
            l('r', 17, { add: 2 }),
            l('d', 15, { add: 1 })
          ],
        ]);
        setDraft([]);
        setPot([]);
      }
    ]
  },
  {
    text: "Double- and triple-word tiles multiply the word's score by 2 or 3 respectively. These stack additively, so using one of each in a word will result in a 5x multiplier.",
    initial: {
      pot: [], 
      board: [
        [l('l', 2), l("i", 5), l('s', 6), l("t", 1), l("e", 4), l('d', 7)],
        [
          l('w', 18, { add: 1 }), 
          l('o', 16), 
          l('r', 17, { add: 2 }),
          l('d', 15, { add: 1 })
        ],
      ],
      selected: [],
      draft: []
    },
    transitions: [
      ({ pot, setPot }) => { setPot([...pot, l('l', 99),]) },
      ({ pot, setPot }) => { setPot([...pot, l('a', 19, { multiply: 2 })]) },
      ({ pot, setPot }) => { setPot([...pot, l('y', 20, { multiply: 3 })]) },
      ({ pot, setPot }) => { setPot([...pot, l('l', 21, { multiply: 2 })]) },
      () => {},
      ({draft, setDraft}) => { setDraft([...draft, l('d', 7)]); },
      ({draft, setDraft}) => { setDraft([...draft, l("e", 4)]); },
      ({draft, setDraft}) => { setDraft([...draft, l("t", 1)]); },
      ({draft, setDraft}) => { setDraft([...draft, l('a', 19, { multiply: 2 })]); },
      ({draft, setDraft}) => { setDraft([...draft, l("i", 5)]); },
      ({draft, setDraft}) => { setDraft([...draft, l('l', 2)]); },
      ({draft, setDraft}) => { setDraft([...draft, l('s', 6)]); },
      () => {},
      ({setBoard, setDraft, setPot}) => {
        setBoard([
          [
            l('d', 7), 
            l("e", 4), 
            l("t", 1),
            l('a', 19, { multiply: 2 }), 
            l("i", 5),
            l('l', 2), 
            l('s', 6)
          ],
          [
            l('w', 18, { add: 1 }), 
            l('o', 16), 
            l('r', 17, { add: 2 }),
            l('d', 15, { add: 1 })
          ],
        ]);
        setDraft([]);
        setPot([l('l', 99), l('y', 20, { multiply: 3 }), l('l', 21, { multiply: 2 })]);
      },
      ({draft, setDraft}) => { setDraft([...draft, l('w', 18, { add: 1 })]); },
      ({draft, setDraft}) => { setDraft([...draft, l('o', 16)]); },
      ({draft, setDraft}) => { setDraft([...draft, l('r', 17, { add: 2 })]); },
      ({draft, setDraft}) => { setDraft([...draft, l('l', 21, { multiply: 2 })]); },
      ({draft, setDraft}) => { setDraft([...draft, l('d', 15, { add: 1 })]); },
      ({draft, setDraft}) => { setDraft([...draft, l('l', 99),]); },
      ({draft, setDraft}) => { setDraft([...draft, l('y', 20, { multiply: 3 })]); },
      () => {},
      ({setBoard, setDraft, setPot}) => {
        setBoard([
          [
            l('d', 7), 
            l("e", 4), 
            l("t", 1),
            l('a', 19, { multiply: 2 }), 
            l("i", 5),
            l('l', 2), 
            l('s', 6)
          ],
          [
            l('w', 18, { add: 1 }), 
            l('o', 16), 
            l('r', 17, { add: 2 }),
            l('l', 21, { multiply: 2 }),
            l('d', 15, { add: 1 }),
            l('l', 99),
            l('y', 20, { multiply: 3 })
          ],
        ]);
        setDraft([]);
        setPot([]);
      },
    ]
  },
  {
    text: "Blank tiles can be used as any letter; to use one, tap/click on it, then type the letter you want it to represent. Blanks will also be automatically selected when needed.",
    initial: {
      board: [
        [
          l('d', 7), 
          l("e", 4), 
          l("t", 1),
          l('a', 19, { multiply: 2 }), 
          l("i", 5),
          l('l', 2), 
          l('s', 6)
        ],
        [
          l('w', 18, { add: 1 }), 
          l('o', 16), 
          l('r', 17, { add: 2 }),
          l('l', 21, { multiply: 2 }),
          l('d', 15, { add: 1 }),
          l('l', 99),
          l('y', 20, { multiply: 3 })
        ],
      ],
      pot: [],
      draft: [],
      selected: [],
    },
    transitions: [
      ({ pot, setPot }) => { setPot([...pot, l('b', 101),]) },
      ({ pot, setPot }) => { setPot([...pot, l('l', 102)]) },
      ({ pot, setPot }) => { setPot([...pot, l('a', 103)]) },
      ({ pot, setPot }) => { setPot([...pot, l('n', 104)]) },
      ({ pot, setPot }) => { setPot([...pot, { blank: true, id: 105 }]) },
      () => {},
      ({draft, setDraft}) => { setDraft([...draft, l('b', 101)]); },
      ({draft, setDraft}) => { setDraft([...draft, l('l', 102)]); },
      ({draft, setDraft}) => { setDraft([...draft, l('a', 103)]); },
      ({draft, setDraft}) => { setDraft([...draft, l('n', 104)]); },
      ({draft, setDraft}) => { setDraft([...draft, { blank: true, id: 105, letter: 'k' }]); },
      () => {},
      ({setBoard, setDraft, setPot}) => {
        setBoard([
          [
            l('d', 7), 
            l("e", 4), 
            l("t", 1),
            l('a', 19, { multiply: 2 }), 
            l("i", 5),
            l('l', 2), 
            l('s', 6)
          ],
          [
            l('w', 18, { add: 1 }), 
            l('o', 16), 
            l('r', 17, { add: 2 }),
            l('l', 21, { multiply: 2 }),
            l('d', 15, { add: 1 }),
            l('l', 99),
            l('y', 20, { multiply: 3 })
          ],
          [
            l('b', 101),
            l('l', 102),
            l('a', 103),
            l('n', 104),
            { blank: true, id: 105, letter: 'k' }
          ]
        ]);
        setDraft([]);
        setPot([]);
      },
    ],
  },
  {
    text: "Once the bag is empty, try to use any remaining tiles before viewing your final score! You get a 10-point bonus if you never go bust, and a 20-point bonus if you use all 77 tiles!",
    initial: {
      pot: [], 
      board: [
        [
          l('d', 7), 
          l("e", 4), 
          l("t", 1),
          l('a', 19, { multiply: 2 }), 
          l("i", 5),
          l('l', 2), 
          l('s', 6)
        ],
        [
          l('w', 18, { add: 1 }), 
          l('o', 16), 
          l('r', 17, { add: 2 }),
          l('l', 21, { multiply: 2 }),
          l('d', 15, { add: 1 }),
          l('l', 99),
          l('y', 20, { multiply: 3 })
        ],
        [
          l('b', 101),
          l('l', 102),
          l('a', 103),
          l('n', 104),
          { blank: true, id: 105, letter: 'k' }
        ],
        [
          l('f', 22), 
          l('i', 23),
          l('r', 24),
          l('e', 25, { add: 2 }),
        ],
        [
          l('n', 26, { add: 2 }), 
          l('e', 27), 
          l('a', 28),
          l('t', 29, { multiply: 2 }),
        ],
      ],
      selected: [],
      draft: []
    },
    transitions: [
      ({ pot, setPot }) => { setPot([...pot, l('a', 98),]) },
      () => {},
      ({draft, setDraft}) => { setDraft([...draft, l('a', 98),]); },
      ({draft, setDraft}) => { setDraft([...draft, l('f', 22)]); },
      ({draft, setDraft}) => { setDraft([...draft, l('i', 23),]); },
      ({draft, setDraft}) => { setDraft([...draft, l('r', 24),]); },
      ({draft, setDraft}) => { setDraft([...draft, l('e', 25, { add: 2 }),]); },
      () => {},
      ({setBoard, setDraft, setPot}) => {
        setBoard([
          [
            l('d', 7), 
            l("e", 4), 
            l("t", 1),
            l('a', 19, { multiply: 2 }), 
            l("i", 5),
            l('l', 2), 
            l('s', 6)
          ],
          [
            l('w', 18, { add: 1 }), 
            l('o', 16), 
            l('r', 17, { add: 2 }),
            l('l', 21, { multiply: 2 }),
            l('d', 15, { add: 1 }),
            l('l', 99),
            l('y', 20, { multiply: 3 })
          ],
          [
            l('b', 101),
            l('l', 102),
            l('a', 103),
            l('n', 104),
            { blank: true, id: 105, letter: 'k' }
          ],
          [
            l('a', 98),
            l('f', 22), 
            l('i', 23),
            l('r', 24),
            l('e', 25, { add: 2 }),
          ],
          [
            l('n', 26, { add: 2 }), 
            l('e', 27), 
            l('a', 28),
            l('t', 29, { multiply: 2 }),
          ],
        ]);
        setDraft([]);
        setPot([]);
      },
    ]
  }
]

const PageX: React.FC<{page: Page, onDone: () => void}> = ({ page, onDone }) => {
  const [pot, setPot] = useState(page.initial.pot);
  const [board, setBoard] = useState(page.initial.board);
  const [isToppling, setIsToppling] = useState(false);
  const [draft, setDraft] = useState(page.initial.draft);
  const [subpage, setSubpage] = useState(-3);

  useEffect(() => {
    const timer = setInterval(() => {
      setSubpage(subpage + 1);
      if (subpage < 0) return;
      const transition = page.transitions[subpage];
      if (transition === undefined) {
        clearInterval(timer);
        onDone();
        return;
      }
      transition({
        pot, setPot, 
        board, setBoard, 
        draft, setDraft, 
        isToppling, setIsToppling
      });
    }, 500);
    return () => clearInterval(timer);
  });

  return <div> 
    <SummaryArea>
      <p>{page.text}</p>
    </SummaryArea>
    <GameArea>
      <Tower 
        tiles={isToppling ? [] : pot} 
        selectedTiles={draft} 
        toppling={isToppling ? pot : []} 
      />
      <Divider />
      <Board 
        words={board} 
        arrangement={draft} 
        selectedTiles={draft} 
        triedIllegalWord={false}
      />
    </GameArea>
  </div>
}

export const Instructions: React.FC<InstructionsProps> = ({showGame}) => {
  const [page, setPage] = useState(0);
  const [pageDone, setPageDone] = useState(false);

  return <InstructionsDiv>
    <Title>lexible</Title>
    <Subtitle>tutorial</Subtitle>

    <PageX page={PAGES[page]} key={page} onDone={() => setPageDone(true)}/>

    <BottomRow>
      <div style={page > 0 ? {} : { visibility: 'hidden'}}>
        <Button onClick={() => {setPage(page - 1); setPageDone(false);}}>Back</Button>
      </div>
      <Button 
        onClick={showGame} 
        primary={pageDone && page === PAGES.length - 1 ? "#fcf6d3" : "#e4e4e4"} 
        secondary={pageDone && page === PAGES.length - 1 ? "#e0d48f" : "#b4b4b4"}
      >Close</Button>
      <div style={page < PAGES.length - 1 ? {} : { visibility: 'hidden'}}>
        <Button 
          onClick={() => {setPage(page + 1); setPageDone(false);}} 
          primary={pageDone ? "#fcf6d3" : "#e4e4e4"} 
          secondary={pageDone ? "#e0d48f" : "#b4b4b4"}
        >Next</Button>
      </div>
    </BottomRow>
  </InstructionsDiv>;
}

const Title = styled.div`
  font-size: 30px;
  font-family: "Courier New";
  user-select: none;
  color: black;
  margin-bottom: 0px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
`;

const Subtitle = styled.div`
  font-size: 12px;
  line-height: 5px;
  padding-left: 100px;
  font-family: "Courier New";
  user-select: none;
  color: black;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;

const InstructionsDiv = styled.div`
  padding: 10px;
`;

const Divider = styled.div`    
  width: 100%;
  border-bottom: 2px solid #9ca3c9;
  border-top: 2px solid #d3d9fc;
  height: 0;
  border-radius: 100px;
`;

const GameArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 250px;
`

const BottomRow = styled.div`
  display: flex;
  padding: 20px;
  gap: 20px;
  justify-content: space-between;
`;

const SummaryArea = styled.div`
  height: 150px;
  font-size: 16px;
  padding: 0 10px;

  font-family: "Courier New";
  text-align: center;
`;