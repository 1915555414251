import styled from "styled-components";
import { BagCode } from "./BagCode"
import { Tile, getScore } from "./game";
import { ScoreBoard } from "./ScoreBoard";
import { Button } from "./Button";
import { useState } from "react";
import { Summary } from "./Summary";

export interface HistoryEntry {
  board: Tile[][];
  trash: Tile[];
  code: string;
  pot: Tile[];
  date: string | Date | undefined;
  started: string | Date | undefined;
  completed: string | Date | undefined;
}

interface HistoryProps {
  history: HistoryEntry[];
  closeHistory: () => void;
}

interface ScoredHistoryEntry extends HistoryEntry {
  score: number;
  allTilesBonus: boolean;
  sturdyBonus: boolean;
}

export const History: React.FC<HistoryProps> = ({ history, closeHistory }) => {
  const [entry, setEntry] = useState<ScoredHistoryEntry | undefined>(undefined);
  const [sortBy, setSortBy] = useState("score");

  const scored = history.map(g => ({
    ...g,
    ...getScore(g.board, [], g.trash, g.pot)
  }));

  if (entry) {
    return <Summary
      bag={[]}
      score={entry.score}
      board={entry.board}
      code={entry.code}
      sturdyBonus={entry.sturdyBonus}
      allTilesBonus={entry.allTilesBonus}
      goToMenu={() => setEntry(undefined)}
      backText="Back"
      bagDate={entry.date}
    />
  }

  const sorted = sortBy === "time" 
    ? [...scored].reverse()
    : [...scored].sort((a, b) => b.score - a.score);

  return <Panel>
    <Subtitle>{sortBy === 'time' ? 'game log' : 'high scores'}</Subtitle>
    <ButtonRow>
      <Button onClick={closeHistory} primary="#e4e4e4" secondary="#b4b4b4">Back</Button>
      <Button 
        onClick={() => setSortBy(sortBy === 'time' ? 'score' : 'time')}
      >Sort by {sortBy === 'time' ? 'Score' : 'Recent'}</Button>
    </ButtonRow>
    <TableWrapper>
      <Table>
        {sorted.map(g => {
          const code = g.code;
          return <Row key={code}>
            <BagCode code={code} date={g.date} fixWidth={true} />
            <ScoreBoard score={g.score} fixWidth={true} />
            <Button onClick={() => setEntry(g)}>{'►'}</Button>
          </Row>
        })}
      </Table>
    </TableWrapper>
    <Footer>
      {history.length > 3 && <ButtonRow><Button onClick={closeHistory} primary="#e4e4e4" secondary="#b4b4b4">Back</Button></ButtonRow>}
    </Footer>
  </Panel>
}


const Subtitle = styled.div`
  font-size: 30px;
  font-family: "Courier New";
  user-select: none;
  color: black;
  margin-bottom: 0px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  justify-content: center;
  display: flex;
`;

const Footer = styled.div`
  margin-bottom: 30px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  align-items: start;
`;

const Panel = styled.div`
  overflow: auto;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ButtonRow = styled.div`
  display: flex;
  margin: 10px;
  gap: 20px;
  justify-content: center;
`;