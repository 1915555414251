import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import doNotTrack from 'analytics-plugin-do-not-track'

const ANALYTICS = Analytics({
  app: 'lexible',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-WFW0QQ3FNP'],
      anonymizeIp: true,
      customDimensions: {
        bagCode: 'dimension1',
        isDailyBag: 'dimension2',
        score: 'dimension3',
        didGetSturdyBonus: 'dimension4',
        didGetAllTilesBonus: 'dimension5',
        isValid: 'dimension6'
      },
    }),
    doNotTrack(),
  ]
});

ANALYTICS.page();

export function trackStartGame({ 
  bagCode, 
}: { bagCode: string }) {
  console.log('bag-start');
  ANALYTICS.track("bag-start", {
    bagCode
  })
}

export function trackEnterGuess({ 
  bagCode, 
  isValid,
}: { bagCode: string, isValid: boolean }) {
  console.log('bag-guess');
  ANALYTICS.track("bag-guess", {
    bagCode,
    isValid
  })
}

export function trackFinishGame({ 
  bagCode, 
  didGetSturdyBonus,
  didGetAllTilesBonus,
}: { 
  bagCode: string, 
  didGetSturdyBonus: boolean, 
  didGetAllTilesBonus: boolean 
}) {
  console.log('bag-complete');
  ANALYTICS.track("bag-complete", {
    bagCode, 
    didGetSturdyBonus,
    didGetAllTilesBonus,
  })
}

export function trackShareSummary(params: {}) {
  console.log('share');
  ANALYTICS.track("share", {});
}