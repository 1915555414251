import styled from "styled-components";
import { Button } from "./Button";
import { useState } from "react";
import { getDailyCode, getScore, randomCode } from "./game";
import { ScoreBoard } from "./ScoreBoard";
import { HistoryEntry } from "./History";

interface NewGameMenuProps {
  closeMenu: () => void;
  startNewGame: (code?: string, date?: Date) => void;
  history: HistoryEntry[];
}

export const NewGameMenu: React.FC<NewGameMenuProps> = ({
  closeMenu, 
  startNewGame,
  history
}) => {
  const [code, setCode] = useState(randomCode());
  const [editedCode, setEditedCode] = useState(false);
  const date = new Date();
  const dailyCode = getDailyCode(date);
  const todayEntry = history.find((s) => s.code === dailyCode);
  return <MenuDiv>
    <Title>New Game</Title>
    <Row>
      <Button 
        onClick={() => startNewGame(dailyCode, date)}
        disabled={todayEntry !== undefined}
      >Daily Bag</Button>
      {todayEntry && 
        <ScoreBoard 
          score={getScore(todayEntry.board, [], todayEntry.trash, todayEntry.pot).score}
        />}
    </Row>
    <Row>
      <CodeInput 
        type="text" 
        placeholder="Code" 
        value={code} 
        onChange={(e) => {setCode(e.target.value); setEditedCode(true);}} 
        onFocus={() => !editedCode && setCode("")}
        onBlur={() => !editedCode && setCode(randomCode())}
        maxLength={6}
        pattern="[A-Za-z]{6}"
      />
      <Button onClick={() => code.length === 6 && startNewGame(code)}>New Game</Button>
    </Row>
    <Button onClick={closeMenu} primary="#e4e4e4" secondary="#b4b4b4">Back</Button>
  </MenuDiv>;
}

const MenuDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-top: 100px;
  align-items: center;
  gap: 50px;
  height: 100%;
`;

const Title = styled.div`
  font-size: 30px;
  font-family: "Courier New";
  user-select: none;
  color: black;
  margin-bottom: 50px;
`;

const CodeInput = styled.input`
  height: 15px;
  width: 60px;
  padding: 5px 10px;
  display: inline-block;
  max-width: fit-content;
  border-radius: 5px;
  background-color: #d3d9fc;
  border: 1px solid #a3aad2;
  box-shadow: -1px 1px #a3aad2;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  flex-shrink: 0;
  font-size: 16px;
  font-family: "Courier New";
  user-select: none;
  color: #4e493d;

  &:focus {
    outline: none;
  }
`;

const Row = styled.div`
  display: flex;
  padding: 20px;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;