import { ReactNode } from "react";
import styled from "styled-components";

interface ButtonProps {
  onClick?: () => void;
  children: ReactNode;
  primary?: string;
  secondary?: string;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({ 
  onClick, 
  children,
  primary,
  secondary,
  disabled
}) => {
  return <ButtonSpan 
    onClick={(e) => {
      if (disabled) return;
      e.stopPropagation();
      onClick && onClick();
    }}
    $primary={primary}
    $secondary={secondary}
    $disabled={disabled ?? false}
  >
    <TileInner>{children}</TileInner>
  </ButtonSpan>;
}

const ButtonSpan = styled.div<{ 
  $primary?: string; 
  $secondary?: string;
  $disabled?: boolean;
}>`
  height: 15px;
  max-width: fit-content;
  display: inline-block;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: ${({$primary}) => $primary ?? "#fcf6d3"};
  border: 1px solid ${({$secondary}) => $secondary ?? "#e0d48f"};
  box-shadow: -1px 1px ${({$secondary}) => $secondary ?? "#e0d48f"};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;

  ${({ $disabled }) => $disabled ? "cursor: default; opacity: 0.6;" : ""}
`;


const TileInner = styled.div`
  font-size: 16px;
  font-family: "Courier New";
  user-select: none;
  color: #4e493d;
`;
