import styled from "styled-components";
import { Button } from "./Button";
import { useState } from "react";
import { NewGameMenu } from "./NewGameMenu";
import { HistoryEntry } from "./History";

interface MenuProps {
  closeMenu: () => void;
  showInstructions: () => void;
  startNewGame: (code?: string, date?: Date) => void;
  history: HistoryEntry[];
  showHistory:  () => void;
}

export const Menu: React.FC<MenuProps> = ({
  closeMenu, 
  showInstructions,
  startNewGame,
  showHistory,
  history
}) => {
  const [showingNewGame, setShowingNewGame] = useState(false);

  if (showingNewGame) {
    return <NewGameMenu 
      closeMenu={() => setShowingNewGame(false)} 
      startNewGame={startNewGame}
      history={history}
    />;
  }

  return <MenuDiv>
    <Title>lexible</Title>
    <Button onClick={showInstructions}>Tutorial</Button>
    <Button onClick={() => showHistory()}>High Scores</Button>
    <Button onClick={() => setShowingNewGame(true)}>New Game</Button>
    <Button onClick={closeMenu} primary="#e4e4e4" secondary="#b4b4b4">Back</Button>
  </MenuDiv>;
}

const MenuDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-top: 100px;
  align-items: center;
  gap: 50px;
  height: 100%;
`;

const Title = styled.div`
  font-size: 30px;
  font-family: "Courier New";
  user-select: none;
  color: black;
  margin-bottom: 40px;
`;
