import styled from "styled-components";

interface MenuButtonProps {
  onClick?: () => void;
}

export const MenuButton: React.FC<MenuButtonProps> = ({ 
  onClick, 
}) => {
  return <ButtonSpan 
    onClick={(e) => {
      e.stopPropagation();
      onClick && onClick();
    }}
  >
    <TileInner>Menu</TileInner>
  </ButtonSpan>;
}

const ButtonSpan = styled.div`
  height: 15px;
  display: inline-block;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #fcf6d3;
  border: 1px solid #e0d48f;
  box-shadow: -1px 1px #e0d48f;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;


const TileInner = styled.div`
  font-size: 16px;
  font-family: "Courier New";
  user-select: none;
  color: #4e493d;
`;
