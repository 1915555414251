import styled from "styled-components";
import { TileX } from "./TileX";
import { BlankTile, Tile } from "./game";

interface TowerProps {
  tiles: Tile[];
  selectTile?: (tile: Tile) => void;
  deselectBlankTile?: () => void;
  deselectTile?: (index: number) => void;
  selectedTiles: Tile[];
  toppling: Tile[];
  selectedBlankTile?: BlankTile | undefined;
}

export const Tower: React.FC<TowerProps> = ({ 
  tiles, 
  selectTile, 
  deselectTile,
  selectedTiles,
  toppling,
  selectedBlankTile,
  deselectBlankTile
}) => {
  return <TowerDiv>
    {[...tiles, ...toppling].map((tile, i) => {
      const isSelectedBlankTile = selectedBlankTile?.id === tile.id;
      const isSelected = selectedTiles.find(
        selectedTile => selectedTile.id === tile.id
      ) !== undefined;
      return <TileX 
        tile={tile} 
        key={i} 
        onClick={
          isSelected 
          ? () => deselectTile && deselectTile(i) 
          : isSelectedBlankTile
          ? () => deselectBlankTile && deselectBlankTile()
          : selectTile
        } 
        isSelected={isSelected || isSelectedBlankTile}
        isToppling={toppling.length > 0}
      />;
    })}
  </TowerDiv>;
}

const TowerDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 27px;
  gap: 3px;
  row-gap: 8px;
`;