import { BagCode } from "./BagCode";
import { Button } from "./Button";
import { TileX } from "./TileX";
import { Tile, isLetterTile } from "./game";
import styled from "styled-components";

interface BagProps {
  bag: Tile[],
  closeBag: () => void;
  code: string;
}

type TileCount = Tile & {count: number};

export const Bag: React.FC<BagProps> = ({
  bag,
  closeBag,
  code
}) => {
  const letters = bag.filter(isLetterTile);
  const counts: TileCount[] = [
    ...("abcdefghijklmnopqrstuvwxyz".split("").map(
      letter => ({letter, count: bag.filter(tile => tile.letter === letter).length})
    )),
    {blank: true, count: bag.filter(tile => tile.blank).length},
    {letter: " ", add: 1, count: letters.filter(tile => tile.add === 1).length},
    {letter: " ", add: 2, count: letters.filter(tile => tile.add === 2).length},
    {letter: " ", multiply: 2, count: letters.filter(tile => tile.multiply === 2).length},
    {letter: " ", multiply: 3, count: letters.filter(tile => tile.multiply === 3).length}
  ];
  return <BagDiv>
    <TableWrapper><Table>
      {counts.map(tileCount => {
        const zero = tileCount.count === 0
        return <Row $fade={zero} key={JSON.stringify(tileCount)}>
          <TileX tile={tileCount} isDraft={zero}/>
          <Label $fade={zero}>{tileCount.count}</Label>
        </Row>
      })}
    </Table></TableWrapper>
    <BottomRow>
      <BagCode code={code} />
      <Button onClick={closeBag} primary="#e4e4e4" secondary="#b4b4b4">Close</Button>
    </BottomRow>
  </BagDiv>;
}

const BagDiv = styled.div`
  padding: 10px;
  overflow: hidden;
  height: 600px;
  height: 100%;
  padding-top: 40px;
`;

const TableWrapper = styled.div`
  justify-content: center;
  display: flex;
`;

const Label = styled.span<{$fade: boolean}>`
  ${({ $fade }) => $fade ? "opacity: .60;" : ""}
`

const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  overflow: hidden;
  height: 400px;
  width: calc(min(80%, 300px));
  align-items: center;
`;

const Row = styled.div<{$fade: boolean}>`
  ${({ $fade }) => $fade ? "opacity: .50;" : ""}
  display: flex;
  align-items: center;
  gap: 10px;
  width: 55px;
`;

const BottomRow = styled.div`
  display: flex;
  padding: 20px;
  gap: 20px;
  justify-content: center;
`;