import { useState } from "react";

function getLocalValue<T>(key: string, defaultValue: T) {
  try {
      const value = JSON.parse(localStorage.getItem(key) || "null");
      if (value === null) {
          return defaultValue;
      }
      return value;
  } catch (error) {
      return defaultValue;
  }
}

function setLocalValue<T>(key: string, value: T) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function useLocalState<T>(key: string, defaultValue: T): [T, (v: T) => void] {
  const [memVal, setMemVal] = useState<T>(getLocalValue(key, defaultValue));

  const setValue = (value: T) => {
      setMemVal(value);
      setLocalValue(key, value);
  }

  return [memVal, setValue];
}