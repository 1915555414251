import styled from "styled-components";
import { Tile, isLetterTile } from "./game";

interface TileXProps {
  tile: Tile;
  onClick?: (tile: Tile) => void;
  isSelected?: boolean;
  isIllegal?: boolean;
  isDraft?: boolean;
  isToppling?: boolean;
}

export const TileX: React.FC<TileXProps> = ({ 
  tile,
  onClick, 
  isSelected, 
  isIllegal,
  isDraft,
  isToppling
}) => {
  const bonus = isLetterTile(tile) 
    ? tile.add === 1 
    ? "=2" 
    : tile.add === 2
    ? "=3"
    : tile.multiply === 2
    ? "x2"
    : tile.multiply === 3
    ? "x3"
    : undefined
    : undefined;
  return <TileSpan 
    onClick={() => onClick && onClick(tile)} 
    $illegal={isIllegal ?? false}
    $selected={isSelected ?? false}
    $bonus={bonus}
    $draft={isDraft ?? false}
    $toppling={isToppling ?? false}
  >
    <TileInner>{ tile.letter ?? '?' }</TileInner>
    {bonus && <TileBonus $up={tile.letter && tile.add ? true : false}>{bonus[1]}</TileBonus>}
    {tile.letter && tile.blank && <TileBonus $up={true}>?</TileBonus>}
  </TileSpan>;
}

const TileSpan = styled.div<{ 
  $illegal: boolean; 
  $selected: boolean;
  $bonus: string | undefined; 
  $draft: boolean;
  $toppling: boolean;
}>`
  width: 12px;
  height: 15px;
  padding: 5px;
  background-color: #fcf6d3;
  border-radius: 5px;
  border: 1px solid #e0d48f;
  box-shadow: -1px 1px #e0d48f;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;

  ${({ $illegal }) => $illegal ? `
    background-color: #e76969;
    border: 1px solid #be5b5b;
    box-shadow: -1px 1px #be5b5b;
  ` : ''}

  ${({ $bonus }) => {
    if ($bonus) {
      let primary;
      let secondary;
      if ($bonus === "=2") {
        primary = "#f9c2a7";
        secondary = "#e0ac92";
      } else if ($bonus === "=3") {
        primary = "#f99b6c";
        secondary = "#cf8661";
      } else if ($bonus === "x2") {
        primary = "#d3d9fc";
        secondary = "#a3aad2";
      } else if ($bonus === "x3") {
        primary = "#b1baff";
        secondary = "#8c95d7";
      }
      return `
        background-color: ${primary};
        border: 1px solid ${secondary};
        box-shadow: -1px 1px ${secondary};
      `;
    }
    return "";
  }}

  ${({ $selected }) => $selected ? `
    background-color: #93d0b1;
    border: 1px solid #64997f;
    box-shadow: -1px 1px #64997f;
  ` : ''}

  ${({ $draft }) => $draft ? `
    border-style: dashed;
    box-shadow: none;
    opacity: 0.65;
  ` : ''}

  ${({ $toppling }) => $toppling ? `
    animation: wiggle .25s cubic-bezier(.36,.07,.19,.97) both infinite;
    animation-delay: ${Math.floor(Math.random() * 20) * 0.01}s;
  ` : ""}

  @keyframes wiggle {
    66% {
      transform: rotate(5deg);
    }

    33% {
      transform: rotate(-5deg);
    }
  }
`;

const TileInner = styled.div`
  font-size: 16px;
  font-family: "Courier New";
  text-transform: uppercase;
  user-select: none;
  color: #4e493d;
`;

const TileBonus = styled.div<{ $up: boolean }>`
  position: absolute;
  right: 1.5px;
  font-size: 7px;
  ${({ $up }) => $up ? "top" : "bottom"}: 1px;
`;