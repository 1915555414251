import moment from "moment";
import COMMON_WORDS from "./commonWords.json";

function rep(l: string, n: number) {
  const ret = [];
  for (let i = 0; i < n; i++) {
    ret.push(l);
  }
  return ret;
}
  
const DIST: {[letter: string]: number} = {
  e: 10, 
  a: 6,
  i: 5,
  o: 6,
  t: 6,
  r: 4,
  n: 4,
  d: 3,
  s: 3,
  u: 3,
  l: 2,
  g: 2,
  b: 2,
  c: 2,
  f: 2,
  h: 2,
  m: 2,
  p: 2,
  v: 1,
  w: 1,
  y: 1,
  j: 1,
  k: 1,
  q: 1,
  x: 1,
  z: 2,
}
  
const NUM_DL = 10;
const NUM_DW = 3;
const NUM_TL = 5;
const NUM_TW = 1;
const NUM_BLANK = 2;
const CAN_TOPPLE_HEAT = 16;
const DOES_TOPPLE_HEAT = 20;
  
function anagramit(word: string) {
  return word.split('').sort();
}
  
export class AnagramTrie {
  children = new Map();
  count = 0;
  
  private getOrCreateNode(alphaWord: string[]): AnagramTrie {
    if (alphaWord.length === 0) return this;
    const [first, ...rest] = alphaWord;
    let child = this.children.get(first);
    if (child === undefined) {
      child = new AnagramTrie();
      this.children.set(first, child);
    }
    return child.getOrCreateNode(rest);
  }
  
  private getNode(alphaWord: string[]): AnagramTrie | undefined {
    if (alphaWord.length === 0) return this;
    const [first, ...rest] = alphaWord;
    let child = this.children.get(first);
    if (child === undefined) {
      return child;
    }
    return child.getNode(rest);
  }
  
  registerWord(word: string) {
    const alphaWord = anagramit(word);
    const node = this.getOrCreateNode(alphaWord);
    node.count++;
  }
  
  getAnagramCount(letters: string) {
    const alphaWord = anagramit(letters);
    const node = this.getNode(alphaWord);
    return node?.count ?? 0;
  }

  getSubstringAnagramCount(letters: string) {
    const alphaWord = anagramit(letters);
    const queue: {trie: AnagramTrie, letters: { [letter: string]: number }}[]
      = [{ trie: this, letters: countLetters(alphaWord) }];
    let total = 0;
    while (queue.length) {
      let { trie, letters } = queue.pop()!;
      total += trie.count;
      for (const [letter, child] of Array.from(trie.children.entries())) {
        if (letters[letter]) {
          queue.push({
            trie: child,
            letters: { ...letters, [letter]: letters[letter] - 1 },
          });
        }
      }
    }
    return total;
  }
}

type LexiconObject = number[];
  
export class Lexicon {
  private children = new Map<string, Lexicon>();
  private isValidWord = false;
  
  private getOrCreateNode(letters: string[]): Lexicon {
    if (letters.length === 0) return this;
    const [first, ...rest] = letters;
    let child = this.children.get(first);
    if (child === undefined) {
      child = new Lexicon();
      this.children.set(first, child);
    }
    return child.getOrCreateNode(rest);
  }
  
  private getNode(letters: string[]): Lexicon | undefined {
    if (letters.length === 0) return this;
    const [first, ...rest] = letters;
    let child = this.children.get(first);
    if (child === undefined) {
      return child;
    }
    return child.getNode(rest);
  }
  
  registerWord(word: string) {
    const node = this.getOrCreateNode(word.split(""));
    node.isValidWord = true;
  }
  
  contains(word: string) {
    const node = this.getNode(word.split(""));
    return node?.isValidWord ?? false;
  }

  private wordListH(prefix: string, words: string[]) {
    if (this.isValidWord) {
      words.push(prefix);
    }
    Array.from(this.children.entries()).forEach(([letter, lexicon]) => {
      lexicon.wordListH(prefix + letter, words);
    });
  }

  wordList(): string[] {
    const words: string[] = [];
    this.wordListH("", words);
    return words;
  }

  private toObjectH(obj: LexiconObject) {
    let skips = 0;
    if (this.isValidWord) {
      obj.push(0);
    }
    for (let i = 0; i < 26; i++) {
      const letter = String.fromCharCode(i + 97);
      if (this.children.has(letter)) {
        if (skips > 0) {
          obj.push(i + 1);
        }
        skips = 0;
        const child = this.children.get(letter);
        if (child) {
          obj.push(27);
          child.toObjectH(obj);
          obj.push(28);
        }
      } else {
        skips++;
      }
    }
  }

  toObject(): LexiconObject {
    const obj: LexiconObject = [];
    this.toObjectH(obj);
    return obj;
  }
  
  private static fromObjectH(obj: LexiconObject, offset: number): Lexicon {
    let lex = new Lexicon();
    let code = 1;
    const lexes: [Lexicon, number][] = [];
    for (let idx = offset; idx < obj.length; idx++) {
      const node = obj[idx];
      if (node === 0) {
        lex.isValidWord = true;
      } else if (node === 27) {
        lexes.push([lex, code]);
        lex = new Lexicon();
        code = 1;
      } else if (node === 28) {
        const setLex = lex;
        [lex, code] = lexes.pop()!;
        lex.children.set(String.fromCharCode(code + 96), setLex);
        code++;
      } else {
        code = node;
      }
    }
    return lex;
  }

  static fromObject(obj: LexiconObject): Lexicon {
    return Lexicon.fromObjectH(obj, 0);
  }

  toString(): string {
    return this.toObject().map(c => String.fromCharCode(c + 96)).join("");
  }

  static fromString(str: string): Lexicon {
    return Lexicon.fromObject(str.split("").map(c => c.charCodeAt(0) - 96));
  }
}

export function shouldTopple(oldHeat: number, newHeat: number) {
  return oldHeat >= CAN_TOPPLE_HEAT && newHeat > DOES_TOPPLE_HEAT;
}
  
export function getAnagramTrie() {
  const trie = new AnagramTrie();
  // TODO just remove short words from list
  for (const word of COMMON_WORDS.words) {
    if (word.length >= 4) {
      trie.registerWord(word);
    }
  }
  return trie;
}

export async function getLexiconFromWords() {
  const WORDS = await import("./words.json");
  const trie = new Lexicon();
  for (const word of WORDS.words) {
    trie.registerWord(word);
  }
  return trie;
}

export async function getLexicon() {
  const WORDS = await import("./wordsTrie.json");
  return Lexicon.fromString(WORDS.wordsTrie);
}

function countLetters(letters: string[]): { [letter: string]: number } {
  const result: { [letter: string]: number } = {};
  for (const letter of letters) {
    result[letter] = (result[letter] ?? 0) + 1;
  }
  return result;
}
  
function shuffle<T>(array: T[], seed: number) {
  const shuffled = [...array];
  let currentIndex = array.length;
  while (currentIndex > 0) {
    const { value: randomIndex, seed: seedp } 
      = randomBetween(0, currentIndex, seed);
    seed = seedp;
    currentIndex--;
    [shuffled[currentIndex], shuffled[randomIndex]] = [
      shuffled[randomIndex], shuffled[currentIndex]];
  }

  return {shuffled, seed};
}

export interface LetterTile {
  id?: number;
  letter: string;
  add?: number;
  multiply?: number;
  blank?: boolean;
}

export interface BlankTile {
  id?: number;
  blank: true;
  letter?: undefined;
}
  
export type Tile = LetterTile | BlankTile;
  
export function isLetterTile(tile: Tile): tile is LetterTile {
  return tile.letter !== undefined;
}

export function getHeat(pot: Tile[], trie: AnagramTrie) {
  return trie.getSubstringAnagramCount(
    pot.filter(isLetterTile).map(t => t.letter).join("")
  );
}

export function getToppleProbability(bag: Tile[], pot: Tile[], trie: AnagramTrie) {
  return bag.length > 0 ? "abcdefghijklmnopqrstuvwxyz".split("").map(
    letter => ({letter, count: bag.filter(tile => tile.letter === letter).length})
  ).reduce((n, t) => {
    if (t.count === 0) { return n; }
    const heat = getHeat([...pot, { letter: t.letter }], trie);
    if (shouldTopple(heat, heat)) {
      return n + t.count;
    }
    return n;
  }, 0) / bag.length : 1;
}
  
function random(seed: number) {
  var t = seed += 0x6D2B79F5;
  t = Math.imul(t ^ (t >>> 15), t | 1);
  t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
  return { value: ((t ^ (t >>> 14)) >>> 0) / 4294967296, seed: t };
}
  
function randomBetween(min: number, max: number, seed: number) {
  const {seed: seed2, value} = random(seed);
  const result = Math.floor(value * max) + min;
  return {seed: seed2, value: result};
}
        
export function makeBag(code: string) {
  const letters = [];

  for (let l in DIST) {
    letters.push(...rep(l, DIST[l]));
  }
  let seed = hash(code);
  
  const { seed: seed2, shuffled } = shuffle(letters, seed);
  seed = seed2;
  
  const bag: LetterTile[] = shuffled.map(letter => ({ letter }));
  const indices = shuffled.map((_l, i) => i);
  const { shuffled: bonuses, seed: seed3 } = shuffle(indices, seed2);
  seed = seed3;
    
  let bonusIndex = 0;
    
  for (let i = 0; i < NUM_DL; i++) {
    bag[bonuses[bonusIndex++]].add = 1;
  }

  for (let i = 0; i < NUM_TL; i++) {
    bag[bonuses[bonusIndex++]].add = 2;
  }

  for (let i = 0; i < NUM_DW; i++) {
    bag[bonuses[bonusIndex++]].multiply = 2;
  }

  for (let i = 0; i < NUM_TW; i++) {
    bag[bonuses[bonusIndex++]].multiply = 3;
  }
    
  const bagWithBlanks: Tile[] = [...bag];
  for (let i = 0; i < NUM_BLANK; i++) {
    const {seed: seed2, value: idx} 
      = randomBetween(0, bagWithBlanks.length, seed);
    seed = seed2;
    bagWithBlanks.splice(idx, 0, { blank: true });
  }
  
  // Add `id`s to the tiles
  return bagWithBlanks.map((tile, index) => ({ ...tile, id: index }));
}
  
function tileMatch(tile: Tile, pattern: LetterTile) {
  return pattern.id !== undefined 
    ? tile.id === pattern.id 
    : tile.letter === pattern.letter
}
  
export function randomCode(seed?: number) {
  if (seed === undefined) {
    seed = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
  }
  const letters = "ABCDEFGHJKMNPQRSTWXYZ".toLowerCase().split("");
  let code = "";
  for (let i = 0; i < 6; i++) {
    let { seed: newSeed, value: index } = randomBetween(0, letters.length, seed);
    seed = newSeed
    code += letters[index];
  }
  return code;
}

export function getDailyCode(date: Date) {
  const today = moment(date).startOf('day').utcOffset(0, true);
  const seed = today.valueOf();
  return randomCode(seed);
}
  
export function update(
  word: LetterTile[], 
  pot: Tile[], 
  board: Tile[][],
  lexicon: Lexicon,
): { pot: Tile[], board: Tile[][], message?: string, tilesUsed?: Tile[], validWord: boolean } {
  const validWord = lexicon.contains(word.map(w => w.letter).join(""));
  if (!validWord) {
    return { pot, board, message: "Invalid word", validWord: false };
  }
  if (word.length < 4) {
    return {pot, board, message: "4-letter minimum", validWord: true}
  }
  
  const arrangements = getArrangements(word, pot, board);
  const validArrangements = arrangements.filter(isValidArrangement);
  const sorted = validArrangements.sort(compareArrangements);
  return sorted[0] 
    ? {...sorted[0], validWord: true} 
    : { pot, board, message: "Cannot make word", validWord: true };
}

function isValidArrangement(arrangement: Arrangement) {
  return arrangement.missingLetters === 0 && arrangement.partialWordCount === 0;
}

interface Arrangement {
  pot: Tile[];
  board: Tile[][];
  tilesUsed: Tile[];
  missingLetters: number;
  blanksUsed: number;
  wordsUsed: number;
  usesNewTiles: boolean;
  partialWordCount: number;
  firstFullWordIndex: number | undefined;
  isIdenticalToFirstFullWord: boolean;
}

function alreadyHasSubset(sets: {fullWordIndices: number[]}[], set: number[]) {
  return sets.some(existingSet => 
    set.every(index => existingSet.fullWordIndices.includes(index))
  );
}

function getArrangements(
  letters: LetterTile[],
  pot: Tile[],
  board: Tile[][]
): Arrangement[] {
  // First, get all subsets of full words that can be made
  const fullWordSubsets: {
    fullWords: Tile[][], 
    remainingLetters: LetterTile[],
    fullWordIndices: number[],
  }[] = [];
  const queue: {
    fullWords: Tile[][], 
    otherWords: Tile[][], 
    remainingLetters: LetterTile[],
    fullWordIndices: number[],
    indexOffset: number,
  }[] = [
    { 
      fullWords: [], 
      otherWords: board, 
      remainingLetters: [...letters],
      indexOffset: 0,
      fullWordIndices: [],
    }
  ];
  while (queue.length > 0) {
    let foundOne = false;
    const { 
      fullWords, 
      otherWords, 
      remainingLetters,
      indexOffset,
      fullWordIndices
    } = queue.pop()!;
    // For each word, check if it can be spelled entirely from the remaining letters
    for (let wordIndex = otherWords.length - 1; wordIndex >= 0; wordIndex--) {
      const word = otherWords[wordIndex];
      const wordCopy = [...word];
      const newRemainingLetters = [];
      for (const letter of remainingLetters) {
        const matches = (tile: Tile) => tileMatch(tile, letter);
        let idx = wordCopy.findIndex(matches);
        if (idx !== -1) {
          wordCopy.splice(idx, 1);
        } else {
          newRemainingLetters.push(letter);
        }
      }
      // If so, add it to the subset and push it onto the queue
      if (wordCopy.length === 0) {
        foundOne = true;
        const thisWordOffset = wordIndex + indexOffset;
        if (!alreadyHasSubset(fullWordSubsets, [...fullWordIndices, thisWordOffset]))
          queue.push({
            fullWords: [...fullWords, word],
            otherWords: otherWords.slice(wordIndex + 1),
            remainingLetters: newRemainingLetters,
            indexOffset: thisWordOffset + 1,
            fullWordIndices: [...fullWordIndices, thisWordOffset]
          });
      }
    }
    if (!foundOne) {
      fullWordSubsets.push({fullWords, remainingLetters, fullWordIndices});
    }
  }
  fullWordSubsets.push({ 
    fullWords: [],
    remainingLetters: [...letters],
    fullWordIndices: [],
  });
  return fullWordSubsets.flatMap(({
    fullWords, remainingLetters, fullWordIndices
  }) => {
    const firstFullWordIndex = fullWordIndices.length 
      ? Math.min(...fullWordIndices) 
      : undefined;
    // Then for each subset, get remaining letters including pot and full words
    let missingLetters = [];
    const potCopy = [...pot];
    for (const letter of remainingLetters) {
      const matches = (tile: Tile) => tileMatch(tile, letter);
      let idx = potCopy.findIndex(matches);
      if (idx !== -1) {
        potCopy.splice(idx, 1);
      } else {
        missingLetters.push(letter);
      }
    }
    // Using those letters, pick partial words that gets as many of those letters
    const partialWords = [];
    const otherWords = board.filter((_, i) => !fullWordIndices.includes(i));
    const otherOtherWords = [...otherWords];
    while (missingLetters.length > 0 && otherOtherWords.length > 0) {
      let bestOtherWord = otherOtherWords[0];
      let bestOtherWordIndex = 0;
      let bestOtherWordCount = -1;
      for (
        let otherWordIndex = 0; 
        otherWordIndex < otherOtherWords.length; 
        otherWordIndex++
      ) {
        const otherWord = otherOtherWords[otherWordIndex];
        const otherWordCopy = [...otherWord];
        let count = 0;
        for (const letter of missingLetters) {
          const matches = (tile: Tile) => tileMatch(tile, letter);
          let idx = otherWordCopy.findIndex(matches);
          if (idx !== -1) {
            count++;
            otherWordCopy.splice(idx, 1);
          }
        }
        // Not actually a partial word: a complete word *facepalm*
        if (count === otherWord.length) continue;
        if (bestOtherWord === undefined || count > bestOtherWordCount) {
          bestOtherWord = otherWord;
          bestOtherWordIndex = otherWordIndex;
          bestOtherWordCount = count;
        }
      }
      if (bestOtherWordCount < 1) {
        break;
      }
      partialWords.push(bestOtherWord);
      otherOtherWords.splice(bestOtherWordIndex, 1);
      const newMissingLetters = []
      for (const letter of missingLetters) {
        const matches = (tile: Tile) => tileMatch(tile, letter);
        let idx = bestOtherWord.findIndex(matches);
        if (idx === -1) {
          newMissingLetters.push(letter);
        }
      }
      missingLetters = newMissingLetters;
    }
    // Finally assign with priority: full words, partial words, pot, blanks, placeholder
    // We do this twice, once using partial words, and once not
    return (partialWords.length ? [partialWords, []] : [[]]).map((partialWords) => {
      const usingFullWordsCopy = fullWords.map(w => [...w]);
      const partialWordsCopy = partialWords.map(w => [...w]);
      const potCopy = [...pot];
      let usesNewTiles = false;
      let tilesUsed: Tile[] = [];
      let blanksUsed = 0;
      let missingLetters = 0;
      // Try only using pot and `partialWords`
      outer:
      for (const letter of letters) {
        const matches = (tile: Tile) => tileMatch(tile, letter);

        // Check in full words first
        for (const usingWord of usingFullWordsCopy) {
          let idx = usingWord.findIndex(matches);
          if (idx !== -1) {
            tilesUsed.push(usingWord[idx]);
            usingWord.splice(idx, 1);
            continue outer;
          }
        }
        // Check in partial words
        for (const usingWord of partialWordsCopy) {
          let idx = usingWord.findIndex(matches);
          if (idx !== -1) {
            tilesUsed.push(usingWord[idx]);
            usingWord.splice(idx, 1);
            continue outer;
          }
        }
        // Did not find one in any of the using words, look in pot
        let idx = potCopy.findIndex(matches);
        if (idx !== -1) {
          tilesUsed.push({...potCopy[idx], letter: letter.letter });
          potCopy.splice(idx, 1);
          usesNewTiles = true;
          continue;
        }
        // Did not find letter in pot, try using a blank
        idx = potCopy.findIndex(t => letter.id ? t.id === letter.id : t.blank);
        if (idx !== -1) {
          tilesUsed.push({ letter: letter.letter, id: potCopy[idx].id, blank: true });
          potCopy.splice(idx, 1);
          blanksUsed++;
          continue;
        }
        // Did not find letter in pot, just throw in a fake tile
        tilesUsed.push({ letter: letter.letter });
        missingLetters++;
      }

      const newBoard = [...otherWords];
      if (firstFullWordIndex !== undefined) {
        newBoard.splice(firstFullWordIndex, 0, tilesUsed);
      } else {
        newBoard.push(tilesUsed);
      }

      const isIdenticalToFirstFullWord = fullWords.length ? fullWords[0].every(
        (t, i) => t.letter === tilesUsed[i].letter
      ) : false;
      return {
        pot: potCopy,
        board: newBoard,
        tilesUsed, 
        missingLetters, 
        blanksUsed, 
        wordsUsed: fullWords.length + partialWords.length,
        partialWordCount: partialWords.length,
        usesNewTiles,
        firstFullWordIndex,
        isIdenticalToFirstFullWord
      };
    });
  });
}

function compareArrangements(a: Arrangement, b: Arrangement) {
  if (a.missingLetters !== b.missingLetters) {
    return a.missingLetters - b.missingLetters;
  }
  if (a.partialWordCount !== b.partialWordCount) {
    return a.partialWordCount - b.partialWordCount;
  }
  if ((a.wordsUsed > 1 || b.wordsUsed > 1) && a.wordsUsed !== b.wordsUsed) {
    return b.wordsUsed - a.wordsUsed; 
  }
  if (a.usesNewTiles !== b.usesNewTiles) {
    return b.usesNewTiles ? 1 : -1;
  }
  if (a.wordsUsed !== b.wordsUsed) {
    return b.wordsUsed - a.wordsUsed; 
  }
  if (a.blanksUsed !== b.blanksUsed) {
    return a.blanksUsed - b.blanksUsed;
  }
  if (a.isIdenticalToFirstFullWord !== b.isIdenticalToFirstFullWord) {
    return b.isIdenticalToFirstFullWord ? -1 : 1;
  }
  if (a.firstFullWordIndex !== b.firstFullWordIndex) {
    return (a.firstFullWordIndex ?? 0) - (b.firstFullWordIndex ?? 0);
  }
  return 0;
}

export function guessArrangement(
  letters: LetterTile[], 
  pot: Tile[], 
  board: Tile[][]
) {  
  const arrangements = getArrangements(letters, pot, board);
  const sorted = arrangements.sort(compareArrangements);
  return sorted[0]?.tilesUsed;
}

const TRI_NUMBERS = new Map<number, number>();
export function triangleNumber(n: number) {
  const existing = TRI_NUMBERS.get(n);
  if (existing) return existing;
  let x = 0;
  for (let i = 1; i <= n; i++) x += i;
  TRI_NUMBERS.set(n, x);
  return x;
}

export function getWordScore(tiles: Tile[]) {
  let bonusPoints = 0;
  let mult: number = 0;
  for (const tile of tiles) {
    if (isLetterTile(tile)) {
      bonusPoints += tile.add ?? 0;
      mult += tile.multiply ?? 0;
    }
  }
  const baseScore = tiles.length - 3;
  // const lengthBonus = Math.floor(triangleNumber(tiles.length - 5)) * 2;
  const lengthBonus = 0;
  return (baseScore + bonusPoints) * (mult || 1) + lengthBonus;
}

export function getScore(board: Tile[][], bag: Tile[], trash: Tile[], pot: Tile[]) {
  let score = 0;
  const sturdyBonus = trash.length === 0 && bag.length === 0;
  const allTilesBonus = sturdyBonus && pot.length === 0;
  if (sturdyBonus) score += 10;
  if (allTilesBonus) score += 20;
  for (const word of board) {
    score += getWordScore(word);
  }
  return {score, sturdyBonus, allTilesBonus};
}

function hash(s: string): number {
  var h = 0, l = s.length, i = 0;
  if (l > 0) {
    while (i < l) {
      h = (h << 5) - h + s.charCodeAt(i++) | 0;
    }
  }
  return h;
}
