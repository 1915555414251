import styled from "styled-components";
import { useState } from "react";

interface BagCodeProps {
  code: string;
  date?: Date | string;
  fixWidth?: boolean;
}

export function safeDate(date: Date | string): Date;
export function safeDate(date: undefined): undefined;
export function safeDate(date: Date | string | undefined): Date | undefined;
export function safeDate(date: Date | string | undefined): Date | undefined {
  if (date === undefined) return undefined;
  if (date instanceof Date) return date;
  try {
    return new Date(date);
  } catch (e) {
    console.log(e);
    return new Date();
  }
}

export const BagCode: React.FC<BagCodeProps> = ({ 
  code,
  date,
  fixWidth
}) => {
  const safeD = safeDate(date);
  const [showingDate, setShowingDate] = useState(true);
  return <OuterDiv onClick={() => setShowingDate(!showingDate)}>
    <CodeLabelOuter><TileInner>{safeD ? "Date" : "Code"}</TileInner></CodeLabelOuter><DateLabel $fixWidth={fixWidth ?? false}><TileInner >{safeD && showingDate ? safeD.toLocaleDateString(undefined, {
      year: '2-digit',
      month: 'short',
      day: 'numeric',
    }): <Code>{code.toUpperCase().split("").map((l, i) => <span key={i}>{l}</span>)}</Code>}</TileInner></DateLabel>
  </OuterDiv>;
}

const Code = styled.div`
  display: flex;
  flex-direction: row;
  width: 96px;
  justify-content: space-between;
`

const CodeLabelOuter = styled.div`
  height: 15px;
  padding: 5px 10px;
  display: inline-block;
  border-radius: 5px;
  background-color: #93d0b1;
  border: 1px solid #64997f;
  box-shadow: -1px 1px #64997f;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

const OuterDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TileInner = styled.div`
  font-size: 16px;
  font-family: "Courier New";
  user-select: none;
  color: #4e493d;
  text-align: center;
`;

const DateLabel = styled.div<{ $fixWidth: boolean }>`
${({ $fixWidth }) => $fixWidth ? "width: 96px;" : ""}
  height: 15px;
  padding: 5px 10px;
  display: inline-block;
  border-radius: 5px;
  background-color: #fcf6d3;
  border: 1px solid #e0d48f;
  box-shadow: -1px 1px #e0d48f;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;