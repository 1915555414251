import styled from "styled-components";

interface ScoreBoardProps {
  score: number;
  hideLabel?: boolean;
  fixWidth?: boolean;
  onClick?: () => void;
}

export const ScoreBoard: React.FC<ScoreBoardProps> = ({ 
  score, 
  hideLabel,
  fixWidth,
  onClick
}) => {
  return <ScoreBoardSpan $fixWidth={fixWidth ?? false} onClick={onClick} $pointer={onClick !== undefined}>
    <TileInner>{hideLabel ? <></> : <>Score: </>}{score}</TileInner>
  </ScoreBoardSpan>;
}

const ScoreBoardSpan = styled.div<{ $fixWidth: boolean, $pointer: boolean }>`
  ${({ $fixWidth }) => $fixWidth ? "width: 97px;" : "max-width: fit-content;"}
  ${({ $pointer }) => $pointer ? "cursor: pointer;" : ""}
  height: 15px;
  padding: 5px 10px;
  display: inline-block;
  border-radius: 5px;
  background-color: #d3d9fc;
  border: 1px solid #a3aad2;
  box-shadow: -1px 1px #a3aad2;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
`;

const TileInner = styled.div`
  font-size: 16px;
  font-family: "Courier New";
  user-select: none;
  color: #4e493d;
`;