import { useLocalState } from './useLocalState';
import { LetterTile, BlankTile, Tile, getLexicon, getAnagramTrie, getHeat, getScore, guessArrangement, isLetterTile, makeBag, randomCode, shouldTopple, update, AnagramTrie, Lexicon } from "./game";
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Tower } from './Tower';
import { Board } from './Board';
import { History, HistoryEntry } from './History';
import styled from 'styled-components';
import { ScoreBoard } from './ScoreBoard';
import { MenuButton } from './MenuButton';
import { Menu } from './Menu';
import { Instructions } from './Instructions';
import { Bag } from './Bag';
import { BagButton } from './BagButton';
import { Button } from './Button';
import { Summary } from './Summary';
import checkIsMobile from 'is-mobile';
import { trackEnterGuess, trackFinishGame, trackStartGame } from './analytics';

export function App() {
  const [code, setCode] = useLocalState("code", randomCode());
  const [hasSeenInstructions, setHasSeenInstructions] = useLocalState<boolean>("hasSeenInstructions", false);
  const [bag, setBag] = useLocalState<Tile[]>("bag", makeBag(code));
  const [bagDate, setBagDate] = useLocalState<Date | undefined>("bagDate", undefined);
  const [pot, setPot] = useLocalState<Tile[]>("pot", []);
  const [timesToppled, setTimesToppled] = useLocalState<number>("timesToppled", 0);
  const [trash, setTrash] = useLocalState<Tile[]>("trash", []);
  const [toppling, setToppling] = useState<Tile[]>([]);
  const [board, setBoard] = useLocalState<Tile[][]>("board", []);
  const [message, setMessage] = useState<string | undefined>();
  const [current, setCurrent] = useState<LetterTile[]>([]); 
  const heat = useRef<number>(0);
  const [selectedBlankTile, setSelectedBlankTile] = useState<BlankTile | undefined>(undefined);
  const [started, setStarted] = useLocalState<Date | string>("started", new Date());
  const [history, setHistory] = useLocalState<HistoryEntry[]>("history", []);
  const [hasStartedGame, setHasStartedGame] 
    = useLocalState<boolean>("hasStartedGame", hasSeenInstructions);
  const [isGameDone, setIsGameDone] = useLocalState<boolean>("isGameDone", false);
  const [view, setView] = useState<string>(
    hasSeenInstructions 
      ? hasStartedGame ? isGameDone ? "summary" : "game" : "menu"
      : "instructions"
  );
  const [currentText, setCurrentText] = useState("");
  const anagramTrie = useRef<AnagramTrie>();
  if (anagramTrie.current === undefined) {
    anagramTrie.current = getAnagramTrie()
  }
  const lexicon = useRef<Lexicon>();
  const lexiconLoading = useRef(false);
  useEffect(() => {
    if (lexiconLoading.current) {
      return;
    }
    lexiconLoading.current = true;
    const go = async () => {
      lexicon.current = await getLexicon();
      console.log("Lexicon loaded");
    }
    go();
  }, [])
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const outerRef = useRef<HTMLDivElement>(null);
  const [triedIllegalWord, setTriedIllegalWord] = useState<false | number>(false);
  const isMobile = useRef<boolean | undefined>(undefined);
  if (isMobile.current === undefined) {
    isMobile.current = checkIsMobile();
  }

  const arrangement = guessArrangement(current, pot, board);
  const {score, sturdyBonus, allTilesBonus} = getScore(board, bag, trash, pot);

  const [summary, setSummary] = useState({
    bag,
    score,
    board,
    code,
    sturdyBonus,
    allTilesBonus,
    bagDate
  });

  const toggleKeyboardFocus = () => {
    if (textareaRef.current) {
      if (textareaRef.current === document.activeElement) {
        textareaRef.current.blur();
      } else {
        textareaRef.current.focus();
        resetSelection();
      }
    }
  }

  const startNewGame = (code?: string, date?: Date) => {
    if (code === undefined) {
      code = randomCode();
    }
    code = code.toLowerCase();
    setCode(code);
    setBag(makeBag(code));
    setBagDate(date);
    setPot([]);
    setTrash([]);
    setBoard([]);
    setCurrent([]);
    setStarted(new Date());
    setMessage(undefined);
    setTimesToppled(0);
    setIsGameDone(false);
    setHasStartedGame(true);
    setView("game");
    trackStartGame({ bagCode: code });
  }

  const drawTile = useCallback(() => {
    if (bag.length === 0) return;
    if (toppling.length > 0) return;
    const drawnTile = bag[0];
    const newBag = bag.slice(1);
    let newPot = [...pot, drawnTile];
    const oldHeat = heat.current;
    setMessage(undefined);
    heat.current = getHeat(pot, anagramTrie.current!);

    if (shouldTopple(oldHeat, heat.current)) {
      setTrash([...trash, ...newPot]); 
      setToppling(newPot);
      newPot = [];
      setTimesToppled(timesToppled + 1);
      setMessage("You went bust!");
      setTimeout(() => setToppling([]), 1000);
    }
    setPot(newPot);
    setBag(newBag);
  }, [bag, pot, setPot, setTrash, setBag, trash, timesToppled, setTimesToppled, toppling, setToppling]);

  const showBag = () => setView("bag");
  const showHistory = () => setView("history");
  const showSummary = () => setView("summary");
  const showMenu = () => setView("menu");
  const showGame = () => {
    setView("game");
    setHasSeenInstructions(true);
  };
  const showInstructions = () => setView("instructions");

  const checkWord = useCallback(() => {
    const lex = lexicon.current;
    if (lex === undefined) {
      setMessage("Lexicon still loading...");
      return;
    }
    const { pot: newPot, board: newBoard, message, validWord } 
      = update(current, pot, board, lex);
    if (validWord && message) {
      setMessage("Valid word");
      setTriedIllegalWord(false);
    } else if (validWord) {
      const {score: increasedScore} = getScore(newBoard, bag, trash, newPot);
      setMessage(`Valid word (+${increasedScore - score})`);
      setTriedIllegalWord(false);
    } else {
      setMessage("Invalid word");
      setTriedIllegalWord(triedIllegalWord !== false ? triedIllegalWord + 1 : 1);
    }
  }, [current, triedIllegalWord, board, pot, trash, bag, score]);

  const submitCurrent = useCallback(() => {
    if (toppling.length > 0) return;
    const lex = lexicon.current;
    if (lex === undefined) {
      setMessage("Lexicon still loading...");
      return;
    }
    const { pot: newPot, board: newBoard, message } 
      = update(current, pot, board, lex);
    setPot(newPot);
    setBoard(newBoard);
    setMessage(undefined);
    if (!message) {
      if (textareaRef.current) {
        textareaRef.current.value = "";
      }
      setCurrent([]);
      setTriedIllegalWord(false);
    } else {
      setMessage(message);
      setTriedIllegalWord(triedIllegalWord !== false ? triedIllegalWord + 1 : 1);
    }
    trackEnterGuess({
      bagCode: code,
      isValid: message === undefined
    })
  }, [board, current, pot, setPot, setBoard, setCurrent, triedIllegalWord, setTriedIllegalWord, toppling, code]);

  const selectExisting = (tile: Tile) => {
    if (isLetterTile(tile)) {
      const newCurrent = [...current, tile];
      setCurrent(newCurrent);
      setMessage(undefined);
      if (textareaRef.current) {
        textareaRef.current.value = newCurrent.map(t => t.letter).join("");
        setCurrentText(textareaRef.current.value);
      }
    } else {
      setSelectedBlankTile(tile);
    }
  }

  const deselectTile = (index: number) => {
    const newCurrent = [...current];
    newCurrent.splice(index, 1);
    setCurrent(newCurrent);
    setMessage(undefined);
    if (textareaRef.current) {
      textareaRef.current.value = newCurrent.map(t => t.letter).join("");
      setCurrentText(textareaRef.current.value);
    }
  }

  const currentRef = useRef(current);
  currentRef.current = current;
  const onInput = useCallback((e: FormEvent<HTMLTextAreaElement>) => {
    const input = textareaRef.current;
    if (!input) return;
    const value = input.value.trim();
    const fullValue = input.value;
    setMessage(undefined);
    resetSelection();
    if (value.split("").every(isValidLetter)) {
      setCurrent((current) => {
        const currentWord = current.map(tile => tile.letter).join("");
        if (input.value.startsWith(currentWord)) {
          return [
            ...current, 
            ...value
              .substring(currentWord.length)
              .split("")
              .map(letter => {
                if (selectedBlankTile) {
                  return {...selectedBlankTile, letter};
                } else {
                  return {letter};
                }
              })
          ];
        } else if (currentWord.startsWith(input.value)) {
          return current.slice(0, input.value.length);
        } else {
          return value.split("").map( letter => ({ letter }));
        }
      });
    } else {
      input.value = value.split("").filter(isValidLetter).join("");
    }
    if (fullValue.endsWith(" ")) {
      input.value = value;
      checkWord();
    } else if (fullValue.endsWith("\n")) {
      input.value = value;
      if (currentRef.current.length === 0) {
        drawTile();
      } else {
        submitCurrent();
      }
    } else {
      setTriedIllegalWord(false);
    }
    setCurrentText(input.value);
    setSelectedBlankTile(undefined);
  }, [checkWord, drawTile, submitCurrent, selectedBlankTile]);

  const resetSelection = () => {
    const input = textareaRef.current;
    if (!input) return;
    input.selectionStart = input.selectionEnd = input.value.length; 
  };

  useEffect(() => {
    function onKeypress() {
      textareaRef.current?.focus();
      resetSelection();
    }

    document.addEventListener('keydown', onKeypress);
    return () => document.removeEventListener('keydown', onKeypress);
  }, [checkWord, drawTile, submitCurrent]);

  useEffect(() => {
    const vv = window.visualViewport;
    if (vv) {
      const resizeHandler = () => {
        if (outerRef.current) {
          const h = vv.height === window.innerHeight
            ? "100%"
            : `${vv.height}px`;
          outerRef.current.style.height = h;
        }
      }
      resizeHandler();
  
      vv.addEventListener('resize', resizeHandler);
    }
  });

  if (view === "menu") {
    return <Menu 
      closeMenu={() => isGameDone ? showSummary() : showGame()} 
      showInstructions={showInstructions} 
      startNewGame={startNewGame} 
      history={history}
      showHistory={showHistory}
    />
  }

  if (view === "history") {
    return <History history={history} closeHistory={showMenu} />
  }

  if (view === "summary") {
    return <Summary 
      goToMenu={showMenu} 
      bag={summary.bag} 
      bagDate={summary.bagDate}
      score={summary.score} 
      board={summary.board} 
      code={summary.code} 
      sturdyBonus={summary.sturdyBonus}
      allTilesBonus={summary.allTilesBonus}
    />
  }

  if (view === "bag") {
    return <Bag closeBag={showGame} bag={bag} code={code} />
  }

  if (view === "instructions") {
    return <Instructions showGame={
      () => !hasStartedGame || isGameDone ? showMenu() : showGame()
    }/>
  }

  const endGame = () => {
    const newEntry: HistoryEntry = {
      board,
      trash,
      code,
      pot,
      date: bagDate,
      started,
      completed: new Date(),
    };
    setHistory([...history, newEntry]);
    setSummary({
      bag,
      score,
      board,
      code,
      sturdyBonus,
      allTilesBonus,
      bagDate
    });
    setIsGameDone(true);
    trackFinishGame({ 
      bagCode: code, 
      didGetSturdyBonus: sturdyBonus, 
      didGetAllTilesBonus: allTilesBonus 
    });
    showSummary();
  }

  return (
    <GameDiv ref={outerRef}><InnerGame onClick={toggleKeyboardFocus}>
      <div>
        <ScoreTowerDiv>
          <Tower 
            tiles={pot} 
            selectTile={selectExisting} 
            selectedTiles={arrangement ?? []} 
            deselectTile={deselectTile}
            toppling={toppling}
            selectedBlankTile={selectedBlankTile}
            deselectBlankTile={() => setSelectedBlankTile(undefined)}
          />
          <ScoreBoard score={score} hideLabel={pot.length > 8 || toppling.length > 8}/>
        </ScoreTowerDiv>
        <HiddenTextArea 
          ref={textareaRef} 
          onInput={onInput}
          autoCapitalize='off'
          autoComplete='off'
          autoCorrect='off'
          spellCheck={false}
          value={currentText}
        ></HiddenTextArea>
      </div>
      <Divider/>
      <Board 
        words={board} 
        selectTile={selectExisting} 
        deselectTile={deselectTile} 
        selectedTiles={arrangement ?? []}
        arrangement={arrangement}
        triedIllegalWord={triedIllegalWord}
        sturdyBonus={sturdyBonus}
        allTilesBonus={allTilesBonus}
      />
      {pot.length === 0 && board.length === 0 && current.length === 0 && trash.length === 0 && <>
        {isMobile.current ? <ProTip>
          Tap here and hit return to start
        </ProTip> : <ProTip>
          Hit return to draw a tile
        </ProTip>}
      </>}
      <BottomRow>
        <Message>{message}</Message>
        <BagButton openBag={showBag} tileCount={bag.length} />
        {bag.length === 0 
          ? <Button onClick={endGame}>Done</Button> 
          : <MenuButton onClick={showMenu} />
        }
      </BottomRow>
    </InnerGame></GameDiv>
  );
}

const GameDiv = styled.div`
  margin: 0;
  padding: 5px;
  height: 100%;
  transition: height 0.43s cubic-bezier(0.32, 0.69, 0.6, 0.96);
`;

const InnerGame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
`

const BottomRow = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 12px;
`

const Divider = styled.div` 
  width: 100%;
  border-bottom: 2px solid #9ca3c9;
  border-top: 2px solid #d3d9fc;
  height: 0;
  border-radius: 100px;
`;

const HiddenTextArea = styled.textarea`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
`

const ScoreTowerDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 4px;
`;

const Message = styled.div`
  font-size: 16px;
  font-family: "Courier New";
  user-select: none;
  color: #4e493d;
`;

const ProTip =  styled.div`
  height: 200px;
  font-size: 16px;
  font-family: "Courier New";
  user-select: none;
  color: #4e493d;
  width: 100%;
  display: flex;
  justify-content: center;
`;

function isValidLetter(letter: string) {
  return "abcdefghijklmnopqrstuvwxyz".includes(letter)
}